import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { Server } from '../../components/server/server.component'
import CFAInput from '../cfa-input/cfa-input.component';
import EuroInput from '../euro-input/euro-input.component';
import { Euro } from 'amango-ui'
import StringInput from '../string-input/string-input.component';
import TextInput from '../text-input/text-input.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import styles from './purchase-item.module.css';

const PurchaseItem = ({ canEdit, item, header, onChange, onDelete }) => {
    const { articles } = useContext(Server);
    const [article, setArticle] = useState();
    const [openedArticle, setOpenedArticle] = useState(false);
    const [openedItem, setOpenedItem] = useState(false);
    const [openedPrice, setOpenedPrice] = useState(false);
    const [data, setData] = useState({ barcode: item?.barcode });
    const [newCount, setNewCount] = useState(item?.count);
    const [newItemPrice, setNewItemPrice] = useState(item?.itemPrice)

    useEffect(() => {
        if (item?.articleId) {
            articles.get(item.articleId, setArticle, () => { });
        }
    }, [articles, item])

    if (header) {
        return (
            <div className={styles.header}>
                <div className={styles.purchaseItem}>
                    <span>Code à barre</span>
                    <span>Nom</span>
                    <span>Prix ​​unique</span>
                    <span>Nombre</span>
                    <span>Prix total</span>
                    {canEdit && <span></span>}
                </div>
            </div>
        )
    }

    const articleName = article ? <span>{article.name}</span> : <span className={styles.unknown} onClick={() => setOpenedArticle(true)}>inconnu</span>
    const count = canEdit ? <span className={styles.edit} onClick={() => setOpenedItem(true)}>{item.count}</span> : <span>{item.count}</span>
    const price = canEdit && article ? <span className={styles.edit} onClick={() => setOpenedPrice(true)}><Euro euro={item.itemPrice} /></span> : <Euro euro={item.itemPrice} />
    return (
        <>
            <div className={styles.purchaseItem}>
                <span>{item.barcode}</span>
                {articleName}
                {price}
                {count}
                <Euro euro={item.itemPrice ? item.itemPrice * item.count : 0} />
                {canEdit &&
                    <span className={styles.edit} onClick={() => onDelete(item)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                }
            </div>
            <Modal show={openedArticle} onHide={() => setOpenedArticle(false)}>
                <Modal.Header>
                    <Modal.Title>Neuer Artikel</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput label="Nom" value={data.name} onChange={text => setData({ ...data, name: text })} />
                    <StringInput label="Code à barre" value={data.barcode} onChange={text => setData({ ...data, barcode: text })} />
                    <EuroInput label="Prix d'achat" value={data.pricePurchasing} onChange={text => setData({ ...data, pricePurchasing: text })} />
                    <CFAInput label="Prix ​​de vente" value={data.priceSelling} onChange={text => setData({ ...data, priceSelling: text })} />
                    <TextInput label="Texte" value={data.text} onChange={text => setData({ ...data, text: text })} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedArticle(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedArticle(false);
                        articles.create(data, response => {
                            setArticle(response);
                            item.articleId = response.id;
                            item.itemPrice = response.pricePurchasing;
                            onChange(item);
                        }, () => { });

                    }}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openedItem} onHide={() => setOpenedItem(false)}>
                <Modal.Header>
                    <Modal.Title>Modifier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput label="Nombre" value={newCount} onChange={setNewCount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedItem(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedItem(false);
                        onChange({ ...item, count: newCount });
                    }}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openedPrice} onHide={() => setOpenedPrice(false)}>
                <Modal.Header>
                    <Modal.Title>Prix différent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EuroInput label="Prix d'achat" value={newItemPrice} onChange={setNewItemPrice} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedPrice(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedPrice(false);
                        onChange({ ...item, itemPrice: newItemPrice });
                    }}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PurchaseItem;
