import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';

import styles from './audit-info.module.css';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const AuditInfo = ({ data }) => {

    const popover = <div className={styles.auditPopover}>
        <Popover.Title>Audit-Info</Popover.Title>
        <Popover.Content>
            {(data.created || data.createdBy) && <div className={styles.row}>
                <span>Crée</span>
                <span>{data.created && fmtFR.format(new Date(data.created))}</span>
                <span>par</span>
                <span>{data.createdBy}</span>
            </div>}
            <div className={styles.row}>
                <span>Modifié</span>
                <span>{data.modified && fmtFR.format(new Date(data.modified))}</span>
                <span>par</span>
                <span>{data.modifiedBy}</span>
            </div>
        </Popover.Content>
    </div>;

    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="bottom-end" overlay={popover} >
            <div className={styles.auditInfo}>
                <span>modifié</span>
                <span>{data.modified && fmtFR.format(new Date(data.modified))}</span>
            </div>
        </OverlayTrigger>
    )
}

export default AuditInfo
