/** @format */

import React from 'react'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import { auth } from '../../firebase/firebase.utils'
import { useHistory } from 'react-router-dom'

import styles from './home.module.css'
import ScreenTitle from '../../components/screen-title/screen-title.component'

const HomeScreen = () => {
  const history = useHistory()

  return (
    <ScreenPanel>
      <ScreenTitle label='Bienvenue à KEKE!' />
      <div>
        <div className={styles.card} onClick={() => history.push('/articles')}>
          <div className={styles.title}>Articles</div>
          <div className={styles.body}>Organisez le catalogue d'articles KEKE</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/purchases')}>
          <div className={styles.title}>Achats</div>
          <div className={styles.body}>En tant qu'acheteur, vous saisissez vos achats ici</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/sales')}>
          <div className={styles.title}>Ventes</div>
          <div className={styles.body}>En tant que vendeur, vous saisissez vos ventes ici</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/warehouses')}>
          <div className={styles.title}>Entrepôts</div>
          <div className={styles.body}>Qui a actuellement quels produits en stock?</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/transfers')}>
          <div className={styles.title}>Transfert</div>
          <div className={styles.body}>Envoyez-vous des marchandises ou les transférez-vous d'un entrepôt à un autre?</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/accounts')}>
          <div className={styles.title}>Comptes</div>
          <div className={styles.body}>Où est l'argent?</div>
        </div>
        <div className={styles.card} onClick={() => history.push('/users')}>
          <div className={styles.title}>Utilisateur</div>
          <div className={styles.body}>Gérez l'accès aux données de KEKE ici</div>
        </div>
        <div className={styles.card}>
          <a href='/app-release.apk'>
            <div className={styles.title}>Télécharge l'appli</div>
            <div className={styles.body}>Afin de pouvoir utiliser toutes les fonctions, installe l'application sur ton smartphone Android</div>
          </a>
        </div>
        {auth.currentUser && auth.currentUser.providerData[0].providerId === 'password' && (
          <div className={styles.card} onClick={() => history.push('/change-password')}>
            <div className={styles.title}>Paramètres</div>
            <div className={styles.body}>Modifiez vos paramètres ici</div>
          </div>
        )}
      </div>
    </ScreenPanel>
  )
}

export default HomeScreen
