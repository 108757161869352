import React, { useContext, useEffect, useState } from 'react'
import KeyValueRow from '../../components/key-value-row/key-value-row.component';
import { Server } from '../../components/server/server.component';
import TransferItem from '../../components/transfer-item/transfer-item.component';

const TransferDetailsScreen = ({ match: { params: { id } } }) => {
    const { articles, transfers } = useContext(Server);
    const [articleItems, setArticleItems] = useState();
    const [sourceItems, setSourceItems] = useState([]);
    const [selected, setSelected] = useState();

    const buildSourceItemns = (articleItems, selected) => {
        if (articleItems && selected) {
            var map = {};
            selected.items.forEach(i => map[i.articleId] = i.count);
            const l = [];
            Object.keys(map).forEach(articleId => {
                const art = articleItems[articleId];
                if (art) {
                    l.push({
                        id: articleId,
                        barcode: art.barcode,
                        name: art.name,
                        count: map[articleId],
                    });
                } else {
                    console.error("article not found for articleId", articleId);
                }
            });
            setSourceItems(l);
        }
    }

    useEffect(() => {
        articles.query(null, data => {
            const m = {};
            data.forEach(art => m[art.id] = art);
            setArticleItems(m);
        }, console.log);
    }, [articles]);

    useEffect(() => {
        buildSourceItemns(articleItems, selected);
    }, [articleItems, selected]);

    useEffect(() => {
        transfers.get(id, setSelected, console.error);
    }, [id, transfers]);

    if (!selected) {
        return null;
    }

    return (
        <div>
            <KeyValueRow k="Coli">{selected.name}</KeyValueRow>
            <TransferItem header />
            { sourceItems && sourceItems.map(item => <TransferItem item={item} />)}
        </div>
    )
}

export default TransferDetailsScreen
