import React from 'react';
import { Euro } from 'amango-ui'

import styles from './purchase-info.module.css';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const PurchaseInfo = ({ header, info, onSelect }) => {
    if (header) {
        return <div className={styles.header}>
            <div className={styles.purchaseInfo}>
                <span>Date</span>
                <span>Nom</span>
                <span>Nombre articles</span>
                <span>Prix totale</span>
            </div>
        </div>;
    }

    return (
        <div className={styles.purchaseInfo} onClick={() => onSelect(info)}>
            <span>{fmtFR.format(new Date(info.date))}</span>
            <span>{info.name}</span>
            <span>{info.articleCount}</span>
            <Euro euro={info.total} />
        </div>
    )
}

export default PurchaseInfo;
