import React, { useContext, useEffect, useState } from 'react';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component';
import AccountInfo from '../../components/account-info/account-info.component';
import ButtonBar from '../../components/button-bar/button-bar.component';
import { Button, Modal } from 'react-bootstrap';
import StringInput from '../../components/string-input/string-input.component';
import AccountSelect from '../../components/account-select/account-select.component';
import AmountInput from '../../components/amount-input/amount-input.component';
import UserSelect from '../../components/user-select/user-select.component';
import { notify } from 'react-notify-toast';

const AccountsScreen = () => {
    const { accounts, users } = useContext(Server)
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const [sourceAccount, setSourceAccount] = useState();
    const [targetAccount, setTargetAccount] = useState();
    const [opened1, setOpened1] = useState(false);
    const [opened2, setOpened2] = useState(false);
    const [opened3, setOpened3] = useState(false);
    const [opened4, setOpened4] = useState(false);
    const [accountName, setAccountName] = useState('');
    const [amount, setAmount] = useState({ amount: 0, currency: "CFA" });
    const [text, setText] = useState("");
    const [userItems, setUserItems] = useState();
    const [available, setAvailable] = useState([]);
    const [user, setUser] = useState();

    useEffect(() => {
        accounts.query(null, setItems, setError);
    }, [accounts]);

    useEffect(() => {
        users.query(setUserItems, console.error);
    }, [users]);

    useEffect(() => {
        const hasAccount = user => {
            return !!items.find(acc => acc.userId === user.id);
        }
        if (userItems && items) {
            setAvailable(userItems.filter(u => !hasAccount(u)));
        }
    }, [userItems, items]);

    const save = () => {
        accounts.create({ name: accountName, balance: 0, userId: user }, () => {
            notify.show("crée", 'success', 3000)
            accounts.query(null, setItems, setError)
        }, setError);
        setOpened1(false);
    }

    const createTransfer = () => {
        console.log("transfer", sourceAccount, targetAccount, amount);
        accounts.transfer({
            type: 'TRANSFER',
	        sourceId: sourceAccount,
            targetId: targetAccount,
	        text,
	        amount: amount.amount,
	        currency: amount.currency,
        }, () => {
            notify.show("transféré", 'success', 3000)
            accounts.query(null, setItems, setError)
        }, console.error);
        setOpened2(false);
    }

    const createPayment = () => {
        console.log("payment", sourceAccount, amount);
        accounts.transfer({
            type: 'PAYMENT',
	        sourceId: sourceAccount,
	        text,
	        amount: amount.amount,
	        currency: amount.currency,
        }, () => {
            notify.show("payé", 'success', 3000)
            accounts.query(null, setItems, setError)
        }, console.error);
        setOpened3(false);
    }

    const createDeposit = () => {
        console.log("deposit", sourceAccount, amount);
        accounts.transfer({
            type: 'DEPOSIT',
            targetId: targetAccount,
	        text,
	        amount: amount.amount,
	        currency: amount.currency,
        }, () => {
            notify.show("déposé", 'success', 3000)
            accounts.query(null, setItems, setError)
        }, console.error);
        setOpened4(false);
    }

    return (
        <ScreenPanel>
            <ScreenTitle label="Comptes" />
            <AccountInfo key={"header"} header />
            {
                items.map(item => <AccountInfo key={item.id} account={item} />)
            }
            <ButtonBar>
                <Button onClick={() => { setOpened2(true) }} variant="secondary">Transfert</Button>
                <Button onClick={() => { setOpened3(true) }} variant="secondary">Paiement</Button>
                <Button onClick={() => { setOpened4(true) }} variant="secondary">Dépôt</Button>
                <Button onClick={() => { setOpened1(true) }}>Créer</Button>
            </ButtonBar>
            <Modal show={opened1} onHide={() => setOpened1(false)}>
                <Modal.Header>
                    <Modal.Title>Nouveau compte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserSelect label="Utilisateur" items={available} value={user} onChange={setUser}/>
                    <StringInput label="Nom" value={accountName} onChange={setAccountName} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened1(false)}>Annuler</Button>
                    <Button variant="primary" onClick={save}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={opened2} onHide={() => setOpened2(false)}>
                <Modal.Header>
                    <Modal.Title>Nouveau transfert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AccountSelect label="Du compte" items={items} value={sourceAccount} onChange={setSourceAccount} />
                    <AccountSelect label="Au compte" items={items} value={targetAccount} onChange={setTargetAccount} />
                    <StringInput label="Texte" value={text} onChange={setText} />
                    <AmountInput label="Valeur" value={amount} onChange={setAmount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened2(false)}>Annuler</Button>
                    <Button variant="primary" onClick={createTransfer}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={opened3} onHide={() => setOpened3(false)}>
                <Modal.Header>
                    <Modal.Title>Nouveau paiement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AccountSelect items={items} value={sourceAccount} onChange={setSourceAccount} />
                    <StringInput label="Text" value={text} onChange={setText} />
                    <AmountInput label="Wert" value={amount} onChange={setAmount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened3(false)}>Annuler</Button>
                    <Button variant="primary" onClick={createPayment}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={opened4} onHide={() => setOpened4(false)}>
                <Modal.Header>
                    <Modal.Title>Nouveau dépot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AccountSelect items={items} value={targetAccount} onChange={setTargetAccount} />
                    <StringInput label="Text" value={text} onChange={setText} />
                    <AmountInput label="Wert" value={amount} onChange={setAmount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpened4(false)}>Annuler</Button>
                    <Button variant="primary" onClick={createDeposit}>Sauvegarder</Button>
                </Modal.Footer>
            </Modal>

        </ScreenPanel>
    )
}

export default AccountsScreen
