import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component'

import styles from './warehouse-edit.module.css';

const WarehouseEditScreen = ({ match: { params: { id } } }) => {
    const { users, warehouses } = useContext(Server);
    const [userItems, setUserItems] = useState();
    const [warehouseItems, setWarehouseItems] = useState();
    const [available, setAvailable] = useState([]);
    const history = useHistory();


    useEffect(() => {
        users.query(setUserItems, console.error);
    }, [users]);

    useEffect(() => {
        warehouses.query(null, setWarehouseItems, console.error);
    }, [warehouses]);

    useEffect(() => {
        const hasWarehouse = user => {
            return !!warehouseItems.find(wh => wh.userId === user.id);
        }
        if (userItems && warehouseItems) {
            setAvailable(userItems.filter(u => !hasWarehouse(u)));
        }
    }, [userItems, warehouseItems]);

    const createWarehouse = user => {
        warehouses.create({
            userId: user.id,
            name: user.displayName,
        }, () => history.replace('/warehouses'));
    }

    if (id === 'new' && available.length === 0) {
        return (
            <ScreenPanel>
                Tous les utilisateur ont un entrepôt.
            </ScreenPanel>
        );
    }
    if (id === 'new') {
        return (
            <ScreenPanel>
                <ScreenTitle label="Entrepôt" />
                <div>Coisisez l'utilisateur d'entrepôt:</div>
                {available.map(u => <div className={styles.user} onClick={() => createWarehouse(u)}>{u.displayName}</div>)}
            </ScreenPanel>
        );
    }
    return (
        <ScreenPanel>
            Warehouse Edit {id}
            {JSON.stringify(available)}
        </ScreenPanel>
    )
}

export default WarehouseEditScreen
