import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

import styles from './transfer-list-item.module.css';
import { Button, Modal } from 'react-bootstrap';
import Article from '../article/article.component';

const TransferListItem = ({ item, onChange, left, right }) => {
    const [showDetails, setShowDetails] = useState(false);

    if (item.count === 0) {
        return null;
    }
    const doClick = count => {
        onChange({ count, item });
    }
    return (
        <>
            <div className={styles.transferListItem}>
                {
                    right && <div className={styles.buttons}>
                        <div onClick={() => doClick(-1)}><FontAwesomeIcon icon={faAngleLeft} color="white" /></div>
                        <div onClick={() => doClick(-item.count)}><FontAwesomeIcon icon={faAngleDoubleLeft} color="white" /></div>
                    </div>
                }
                <div onClick={() => setShowDetails(true)} className={styles.description}>
                    <div className={styles.barcode}>{item.barcode}</div>
                    <div className={styles.name}>{item.name}</div>
                </div>
                <div className={styles.count}>{item.count}</div>
                {
                    left && <div className={styles.buttons}>
                        <div onClick={() => doClick(item.count)}><FontAwesomeIcon icon={faAngleDoubleRight} color="white" /></div>
                        <div onClick={() => doClick(1)}><FontAwesomeIcon icon={faAngleRight} color="white" /></div>
                    </div>}
            </div>
            <Modal show={showDetails}>
                <Modal.Header>
                    <Modal.Title>Article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Article data={item} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetails(false)}>Annuler</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TransferListItem
