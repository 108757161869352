import React from 'react';
import Input from '../input/input.component';

const StringInput = ({ label, value, onChange, onKeyPress }) => {
    return (
        <Input type="text" label={label} value={value} onChange={onChange} onKeyPress={onKeyPress} />
    )
}

export default StringInput;
