/** @format */

import React, { createContext } from 'react'
import { auth } from '../../firebase/firebase.utils'
import { trackPromise } from 'react-promise-tracker'
import { notify } from 'react-notify-toast'

export const Server = createContext()

export const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://keke.beckers.cf/api'

var lastToken = null

function imagePathWithToken(name) {
  return `${baseURL}/articles/images/${name}?token=${lastToken}`
}

function executeGet(url, query, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executeFetch(url, requestOptions, callback, errorCallback) {
  trackPromise(
    fetch(url, requestOptions)
      .then(response => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then(error => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then(text => Promise.reject({ message: text }))
      })
      .then(result => callback(result))
      .catch(error => {
        if (error.toString().includes('NetworkError') || error.message.includes('Failed to fetch')) {
          notify.show('Le serveur ne peut pas être atteint. Veuillez réessayer plus tard.', 'error', 10000)
        } else if (errorCallback) {
          errorCallback(error)
        }
      })
  )
}

function executeQuery(type, query, callback, errorCallback) {
  if (query) executeGet('/' + type, { q: query }, callback, errorCallback)
  else executeGet('/' + type, null, callback, errorCallback)
}

function executePost(url, query, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    console.log('executePost ' + url, body)
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    }
    if (body) {
      myHeaders.append('Content-Type', 'application/json')
      requestOptions.body = JSON.stringify(body)
    }
    console.log('executePost ' + url, requestOptions.body)
    const _url = new URL(baseURL + url)
    if (query) {
      _url.search = new URLSearchParams(query)
    }
    executeFetch(_url, requestOptions, callback, errorCallback)
  })
}

function executePut(url, body, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    myHeaders.append('Accept', 'application/json')
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + token)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body),
    }
    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function executeDelete(url, callback, errorCallback) {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    lastToken = token
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }

    executeFetch(baseURL + url, requestOptions, callback, errorCallback)
  })
}

function getItem(type, id, callback, errorCallback) {
  executeGet(`/${type}/${id}`, null, callback, errorCallback)
}

function createItem(type, body, callback, errorCallback) {
  executePost(`/${type}`, null, body, callback, errorCallback)
}

function updateItem(type, id, body, callback, errorCallback) {
  executePut(`/${type}/${id}`, body, callback, errorCallback)
}

function deleteItem(type, id, callback, errorCallback) {
  executeDelete(`/${type}/${id}`, callback, errorCallback)
}

function executeGetBlob(url, errorCallback, downloadName = 'keke.pdf') {
  if (!auth.currentUser) {
    return
  }
  auth.currentUser.getIdToken().then(token => {
    var myHeaders = new Headers()
    if (token) {
      myHeaders.append('Authorization', 'Bearer ' + token)
    }

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    trackPromise(
      fetch(baseURL + url, requestOptions)
        .then(response => {
          console.log('received', response)
          if (response.status === 200) {
            console.log('status 200')
            return response.blob()
          }
          if (response.status === 204) {
            console.log('status 204')
            return {}
          }
          if (response.headers.get('Content-Type') === 'application/json') {
            console.log('error with json')
            return response.json().then(error => Promise.reject(error))
          }
          console.log('error without json')
          return response.text().then(text => Promise.reject(text))
        })
        .then(blob => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = downloadName
          a.click()
        })
        .catch(error => {
          if (errorCallback) errorCallback(error)
        })
    )
  })
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        image: name => imagePathWithToken(name),
        accounts: {
          query: (q, callback, errorCallback) => executeQuery('accounts', q, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('accounts', id, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('accounts', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('accounts', id, body, callback, errorCallback),
          transfer: (body, callback, errorCallback) => executePost('/accounts/transfer', null, body, callback, errorCallback),
        },
        articles: {
          query: (q, callback, errorCallback) => executeQuery('articles', q, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('articles', id, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('articles', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('articles', id, body, callback, errorCallback),
          stock: (id, callback, errorCallback) => executeGet(`/articles/${id}/stock`, null, callback, errorCallback),
          catalog: errorCallback => executeGetBlob('/articles/catalog', errorCallback),
          lookup: (id, provider, callback, errorCallback) => executePost(`/articles/${id}/lookup/${provider}`, null, null, callback, errorCallback),
        },
        purchases: {
          get: (id, callback, errorCallback) => getItem('purchases', id, callback, errorCallback),
          load: (callback, errorCallback) => executeGet('/purchases', null, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('purchases', id, body, callback, errorCallback),
          terminate: (id, callback, errorCallback) => executePost(`/purchases/${id}/terminate`, null, null, callback, errorCallback),
        },
        receptions: {
          byTransfer: (transferId, callback, errorCallback) => executeGet('/receptions', { transferId }, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('receptions', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('receptions', id, body, callback, errorCallback),
          createAndTerminate: (body, callback, errorCallback) => executePost('/receptions/terminate', null, body, callback, errorCallback),
          updateAndTerminate: (id, body, callback, errorCallback) => executePut(`/receptions/${id}/terminate`, body, callback, errorCallback),
        },
        sales: {
          load: (callback, errorCallback) => executeGet('/sales', null, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('sales', id, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('sales', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('sales', id, body, callback, errorCallback),
          terminate: (id, callback, errorCallback) => executePost(`/sales/${id}/terminate`, null, null, callback, errorCallback),
        },
        transfers: {
          get: (id, callback, errorCallback) => getItem('transfers', id, callback, errorCallback),
          byStatus: (status, callback, errorCallback) => executeGet('/transfers', { status }, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('transfers', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('transfers', id, body, callback, errorCallback),
          createAndTerminate: (body, callback, errorCallback) => executePost('/transfers/terminate', null, body, callback, errorCallback),
          updateAndTerminate: (id, body, callback, errorCallback) => executePut(`/transfers/${id}/terminate`, body, callback, errorCallback),
        },
        users: {
          query: (callback, errorCallback) => executeQuery('users', null, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('users', id, callback, errorCallback),
          delete: (id, callback, errorCallback) => deleteItem('users', id, callback, errorCallback),
          setRoles: (userId, body, callback, errorCallback) => executePost(`/users/${userId}`, null, body, callback, errorCallback),
          init: (userId, realName, callback, errorCallback) => executePost(`/users/${userId}/init`, null, { realName }, callback, errorCallback),
          password: (userId, password, callback, errorCallback) => executePost(`/users/${userId}/password`, null, { password }, callback, errorCallback),
        },
        warehouses: {
          query: (q, callback, errorCallback) => executeQuery('warehouses', q, callback, errorCallback),
          get: (id, callback, errorCallback) => getItem('warehouses', id, callback, errorCallback),
          create: (body, callback, errorCallback) => createItem('warehouses', body, callback, errorCallback),
          update: (id, body, callback, errorCallback) => updateItem('warehouses', id, body, callback, errorCallback),
          loadMine: (callback, errorCallback) => executeQuery('warehouses/mine', null, callback, errorCallback),
          inventory: (id, body, callback, errorCallback) => executePost(`/warehouses/${id}/inventory`, null, body, callback, errorCallback),
        },
      }}>
      {children}
    </Server.Provider>
  )
}

export default ServerProvider
