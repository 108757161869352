import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router'
import ButtonBar from '../../components/button-bar/button-bar.component';
import KeyValueRow from '../../components/key-value-row/key-value-row.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import { Server } from '../../components/server/server.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import StringInput from '../../components/string-input/string-input.component';
import { notify } from 'react-notify-toast';

const UserDetailsScreen = () => {
    const { id } = useParams();
    const { users } = useContext(Server);
    const [user, setUser] = useState();
    const [role_, setRole_] = useState();
    const [openedDelete, setOpenedDelete] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        users.get(id, setUser, console.error);
    }, [users, id]);

    useEffect(() => {
        const role = user?.roles ? user?.roles[0] : null;
        setRole_(role);
    }, [user])

    const updateRole = role => {
        setRole_(role);
        users.setRoles(user.id, [role], data => notify.show("modifié", 'success', 3000), console.error);
    }

    const doDelete = () => {
        users.delete(id, () => {
            notify.show("supprimé", 'success', 3000)
            history.goBack()
        }, console.error);
    }

    const doEdit = () => {
        users.init(id, user.displayName, () => {
            notify.show("modifié", 'success', 3000)
            users.get(id, setUser, console.error)
        }, console.error);
    }

    if (!user) {
        return null;
    }
    return (
        <ScreenPanel>
            <KeyValueRow k="Identifiant">{user.id}</KeyValueRow>
            <KeyValueRow k="Nom">{user.displayName} <span onClick={() => setOpenedEdit(true)}><FontAwesomeIcon icon={faPencilAlt} color="blue"/></span></KeyValueRow>
            <KeyValueRow k="Email">{user.email}</KeyValueRow>
            <KeyValueRow k="Rôle">
                <select value={role_} onChange={({ target }) => updateRole(target.value)}>
                    <option>Keine</option>
                    <option value="admin">Administrateur</option>
                    <option value="user">Utilisateur</option>
                    <option value="guest">Visiteur</option>
                </select>
            </KeyValueRow>
            <ButtonBar>
                <Button onClick={() => setOpenedDelete(true)}>Effacer</Button>
            </ButtonBar>
            <Modal show={openedDelete}>
                <Modal.Header>
                    <Modal.Title>Effacer?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Effacer l'utilisateur {user.displayName}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedDelete(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedDelete(false);
                        doDelete();
                    }}>Effacer</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openedEdit}>
                <Modal.Header>
                    <Modal.Title>Change le nom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput value={user.displayName} onChange={name => setUser({...user, displayName: name})} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpenedEdit(false)}>Annuler</Button>
                    <Button variant="primary" onClick={() => {
                        setOpenedEdit(false);
                        doEdit();
                    }}>Changer</Button>
                </Modal.Footer>
            </Modal>
        </ScreenPanel>
    )
}

export default UserDetailsScreen
