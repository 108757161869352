import React from 'react'

import styles from './user-select.module.css'

const UserSelect = ({ label, items, value, onChange }) => {
    return (
        <div className={styles.select}>
        <div className={styles.label}>{label}</div>
        <select value={value} onChange={({ target: { value } }) => onChange(value)}>
            <option>Aucun</option>
            {items && items.map(item => <option value={item.id}>{item.displayName}</option>)}
        </select>
    </div>
    )
}

export default UserSelect
