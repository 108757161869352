import React from 'react'
import TransferListItem from '../transfer-list-item/transfer-list-item.component';

import styles from './transfer-list.module.css';

const TransferList = ({ sourceItems, value, onChange, sourceTitle, targetTitle }) => {
    const doChange = evt => {
        console.log("transferList doChange", evt);
        const sItem = sourceItems.find(t => t.id === evt.item.id);
        const tItem = value.find(t => t.id === evt.item.id);
        if (tItem) {
            tItem.count += evt.count;
            sItem.count -= evt.count;
        } else {
            value.push({ ...sItem, count: evt.count });
            sItem.count -= evt.count;
        }
        onChange([...value]);
    }

    return (
        <div>
            <div className={styles.transferList}>
                <div className={styles.title}>{sourceTitle}</div>
                <div className={styles.title}>{targetTitle}</div>
            </div>
            <div className={styles.transferList}>
                <div className={styles.left}>
                    {
                        sourceItems && sourceItems.map(item => <TransferListItem left item={item} onChange={doChange} />)
                    }
                </div>
                <div className={styles.right}>
                    {
                        value && value.map(item => <TransferListItem right item={item} onChange={doChange} />)
                    }

                </div>
            </div>
        </div>

    )
}

export default TransferList
