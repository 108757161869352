import React from 'react'
import Input from '../input/input.component'

const PasswordInput = ({ label, value, onChange }) => {
    return (
        <Input type="password" label={label} value={value} onChange={onChange} />
    )
}

export default PasswordInput
