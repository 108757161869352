import React, { useContext, useState } from 'react'
import { Server } from '../server/server.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import styles from './warehouse-inventory-item.module.css'

const WarehouseInventoryItem = ({ item, header, sort, onSort, onUpdate }) => {
  const { image } = useContext(Server)

  const headerIcon = colName => {
    if (colName !== sort.column) {
      return <FontAwesomeIcon icon={faSort} />
    }
    return sort.direction !== 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />
  }
  const doSort = colName => {
    if (colName === sort.column) {
      onSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' })
    } else {
      onSort({ ...sort, column: colName })
    }
  }

  if (header) {
    return (
      <div className={styles.header}>
        <div className={styles.item}>
          <div>Image</div>
          <div onClick={() => doSort('barcode')}>Code à barre {headerIcon('barcode')}</div>
          <div onClick={() => doSort('name')}>Nom {headerIcon('name')}</div>
          <div onClick={() => doSort('count')}>Nombre {headerIcon('count')}</div>
          <div>Stock</div>
        </div>
      </div>
    )
  }
  if (item.count === 0) {
    return null
  }
  return (
    <>
      <div className={styles.item}>
        <div>{item.image && <img alt="" src={image(item.barcode)} className={styles.image} />}</div>
        <div>{item.barcode}</div>
        <div>{item.name}</div>
        <div>{item.count}</div>
        <div>
          <input value={item.counted} onChange={({ target: { value } }) => onUpdate(item, value)} />
        </div>
      </div>
    </>
  )
}

export default WarehouseInventoryItem
