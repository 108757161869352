import React from 'react'
import styles from './amount-input.module.css';

const AmountInput = ({ label, value, onChange }) => {
    return (
        <div className={styles.select}>
            <div className={styles.label}>{label}</div>
            <span>
                <input type="number" value={value.amount} onChange={({ target: { value: v } }) => onChange({ ...value, amount: v })} />
                <select value={value.currency} onChange={({ target: { value: v } }) => onChange({ ...value, currency: v })}>
                    <option value="CFA">CFA</option>
                    <option value="EURO">EURO</option>
                </select>
            </span>
        </div>
    )
}

export default AmountInput
