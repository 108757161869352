import React from 'react';

import styles from './screen-title.module.css';

const ScreenTitle = ({ label }) => {

    return (
        <div className={styles.screenTitle}>
            {label}
        </div>
    )
}

export default ScreenTitle;
