import React from 'react'
import { useHistory } from 'react-router';

import styles from './warehouse-info.module.css';

const WarehouseInfo = ({warehouse, header}) => {
    const history = useHistory();

    const select = () => {
        history.push(`/warehouses/${warehouse.id}`);
    };

    if (header) {
        return <div className={styles.header}>
            <div className={styles.warehouseInfo}>
                <span>Nom</span>
            </div>
        </div>;
    }

    return (
        <div className={styles.warehouseInfo} onClick={select}>
            <span>{warehouse.name}</span>
        </div>
    )
}

export default WarehouseInfo
