import styles from './key-value-row.module.css';

const KeyValueRow = ({ k, children }) => {
    return <div className={styles.keyValue}>
        <div>{k}:</div>
        <div>{children}</div>
    </div>;
}

export default KeyValueRow;
