import React, { useContext, useState } from 'react'
import { Server } from '../server/server.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap';
import Article from '../article/article.component';

import styles from './warehouse-item.module.css';

const WarehouseItem = ({ item, header, sort, onSort }) => {
    const { image } = useContext(Server);
    const [showDetails, setShowDetails] = useState(false);

    const headerIcon = colName => {
        if (colName !== sort.column) {
            return <FontAwesomeIcon icon={faSort} />;
        }
        return sort.direction !== 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    const doSort = colName => {
        if (colName === sort.column) {
            onSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
        } else {
            onSort({ ...sort, column: colName });
        }
    }

    if (header) {
        return <div className={styles.header}>
            <div className={styles.item}>
                <div>Image</div>
                <div onClick={() => doSort('barcode')}>Code à barre {headerIcon('barcode')}</div>
                <div onClick={() => doSort('name')}>Nom {headerIcon('name')}</div>
                <div onClick={() => doSort('count')}>Nombre {headerIcon('count')}</div>
            </div>
        </div>;
    }
    if (item.count === 0) {
        return null;
    }
    return (
        <>
            <div className={styles.item} onClick={() => setShowDetails(true)}>
                <div>{item.image && <img alt="" src={image(item.barcode)} className={styles.image} />}</div>
                <div>{item.barcode}</div>
                <div>{item.name}</div>
                <div>{item.count}</div>
            </div>
            <Modal show={showDetails}>
                <Modal.Header>
                    <Modal.Title>Article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Article data={item} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetails(false)}>Annuler</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WarehouseItem
