import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ButtonBar from '../../components/button-bar/button-bar.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component';
import WarehouseInfo from '../../components/warehouse-info/warehouse-info.component';

const WarehousesScreen = () => {
    const { warehouses } = useContext(Server)
    const [items, setItems] = useState([]);
    const history = useHistory();

    useEffect(() => {
        warehouses.query(null, setItems, console.error);
    }, [warehouses])
        
    return (
        <ScreenPanel>
            <ScreenTitle label="Entrepôts" />
            <WarehouseInfo key={"header"} header />
            {
                items.map(item => <WarehouseInfo key={item.id} warehouse={item} />)
            }
            <ButtonBar>
                <Button onClick={() => history.push(`/warehouses/new/edit`)}>Créer</Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default WarehousesScreen
