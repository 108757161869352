import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ButtonBar from '../button-bar/button-bar.component';
import { Server } from '../server/server.component';
import TransferList from '../transfer-list/transfer-list.component';
import { notify } from 'react-notify-toast';

const TransferTransfer = () => {
    const [items, setItems] = useState();
    const [articleItems, setArticleItems] = useState();
    const [source, setSource] = useState();
    const [sourceItems, setSourceItems] = useState([]);
    const [target, setTarget] = useState();
    const [targetItems, setTargetItems] = useState([])
    const { articles, transfers, warehouses } = useContext(Server);
    const history = useHistory();

    const buildSourceItemns = (articleItems, selected) => {
        if (articleItems && selected) {
            var map = {};
            Object.keys(selected.items).forEach(id => map[id] = selected.items[id]);
            const l = [];
            Object.keys(map).forEach(articleId => {
                const art = articleItems[articleId];
                if (art) {
                    l.push({
                        id: articleId,
                        barcode: art.barcode,
                        name: art.name,
                        count: map[articleId],
                    });
                } else {
                    console.error("article not found for articleId", articleId);
                }
            });
            setSourceItems(l);
        }
    }

    useEffect(() => {
        warehouses.query(null, setItems, console.error);
    }, [warehouses])

    useEffect(() => {
        articles.query(null, data => {
            const m = {};
            data.forEach(art => m[art.id] = art);
            setArticleItems(m);
        }, console.log);
    }, [articles]);

    useEffect(() => {
        buildSourceItemns(articleItems, source);
    }, [articleItems, source]);

    const selectSource = ({ target: { value } }) => {
        if (value) {
            warehouses.get(value, data => {
                console.log("selectSource", value, data);
                setSource(data);
                setTargetItems([]);
            }, console.error);
        } else {
            setSource(null);
            setSourceItems(null);
        }
    }

    const selectTarget = ({ target: { value } }) => {
        console.log("selectTarget");
        setTarget(items.find(item => item.id === value));
        setTargetItems([]);
    }

    const transfer = () => {
        const req = {
            sourceId: source.id,
            targetId: target.id,
            type: 'REARRANGE',
            items: targetItems.map(i => ({ articleId: i.id, count: i.count })),
        }
        transfers.createAndTerminate(req, () => {
            notify.show("transféré", 'success', 3000)
            history.go(0)
        });
    }

    return (
        <div>
            <TransferList sourceTitle={<select onChange={selectSource}>
                <option>de l'entrepôt</option>
                {items && items.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>} targetTitle={<select onChange={selectTarget}>
                <option>à l'entrepôt</option>
                {items && items.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>}
                sourceItems={sourceItems} value={targetItems} onChange={setTargetItems} />
            <ButtonBar>
                <Button onClick={transfer} disabled={!source || !target || source.id === target.id || targetItems.length === 0}>Transferez</Button>
            </ButtonBar>
        </div>
    )
}

export default TransferTransfer;
