import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ButtonBar from '../../components/button-bar/button-bar.component'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import PurchaseItem from '../../components/purchase-item-info/purchase-item.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import { useHistory } from "react-router-dom";
import StringInput from '../../components/string-input/string-input.component'
import { Euro } from 'amango-ui'
import AuditInfo from '../../components/audit-info/audit-info.component'
import { notify } from 'react-notify-toast';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const PurchaseDetailsScreen = ({ match: { params: { id } } }) => {
    const { purchases } = useContext(Server);
    const [data, setData] = useState();
    const [openedEdit, setOpenedEdit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        purchases.get(id, setData, () => { });
    }, [purchases, id])

    if (!data) {
        return null;
    }

    const replaceItem = (items, changed) => {
        return items.map(i => i.barcode === changed.barcode ? changed : i);
    }

    const deleteItem = (items, deleted) => {
        console.log("deleteItem", deleted)
        return items.filter(i => i.barcode !== deleted.barcode);
    }

    var dateStr = data.date;
    try {
        dateStr = fmtFR.format(new Date(data.date))
    } catch (e) { }

    const canEdit = !data.addedToWarehouse && !data.addedToAccount;
    const canTerminate = !data.addedToWarehouse && !data.addedToAccount;

    return (
        <>
            <ScreenPanel>
                <ScreenTitle label="Achat" />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 9 }}>
                        <KeyValueRow k="Date">{dateStr}</KeyValueRow>
                        <KeyValueRow k="Nom">{data.name}</KeyValueRow>
                        <KeyValueRow k="Total"><Euro euro={data.total} /></KeyValueRow>
                    </div>
                    {canEdit && <Button style={{ flex: 0 }} onClick={() => setOpenedEdit(true)}>Modifier</Button>}
                </div>
                <AuditInfo data={data} />
                <PurchaseItem header canEdit={canEdit} />
                {
                    data.items && data.items.map(item => <PurchaseItem key={item.barcode} canEdit={canEdit} item={item}
                        onChange={changedItem => setData({ ...data, items: replaceItem(data.items, changedItem) })}
                        onDelete={() => setData({ ...data, items: deleteItem(data.items, item) })} />)
                }
                <ButtonBar>
                    <Button variant="secondary" onClick={() => {
                        history.goBack();
                    }}>Annuler</Button>
                    {canEdit && <Button variant={canTerminate ? "secondary" : "primary"} onClick={() => {
                        purchases.update(id, data, response => {
                            notify.show("L'achat a été enregistré.", 'success', 3000)
                            setData(response);
                        }, console.log);
                    }}>Sauvegarder</Button>}
                    {canTerminate && <Button variant="primary" onClick={() => {
                        purchases.update(id, data, () => {
                            purchases.terminate(id, () => {
                                notify.show("L'achat est terminé.", 'success', 3000)
                                history.push("/purchases");
                            }, error => {
                                if (error.message === "item articleId not set") {
                                    notify.show("L'achat n'a pas pu être effectué car tous les articles n'ont pas été créés.", 'error', 6000)
                                } else {
                                    notify.show("L’achat n’a pas pu être effectué.", 'error', 6000)
                                }
                            });
                        }, console.log);
                    }}>Terminer</Button>}
                </ButtonBar>
            </ScreenPanel>
            <Modal show={openedEdit} onHide={() => setOpenedEdit(false)}>
                <Modal.Header>
                    <Modal.Title>Modifier</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StringInput label="Date" value={data.date} onChange={text => setData({ ...data, date: text })} />
                    <StringInput label="Nom" value={data.name} onChange={text => setData({ ...data, name: text })} />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonBar>
                        <Button variant="primary" onClick={() => setOpenedEdit(false)}>Fermer</Button>
                    </ButtonBar>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PurchaseDetailsScreen
