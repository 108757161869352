import React, { useContext, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import { useHistory } from 'react-router-dom'
import SaleInfo from '../../components/sale-info/sale-info.component'

const SalesScreen = () => {
  const { sales } = useContext(Server)
  const [oldItems, setOldItems] = useState([])
  const [newItems, setNewItems] = useState([])
  const history = useHistory()
  useEffect(() => {
    sales.load(
      data => {
        setOldItems(data.filter(d => d.removedFromWarehouse && d.addedToAccount))
        setNewItems(data.filter(d => !d.removedFromWarehouse || !d.addedToAccount))
      },
      () => {}
    )
  }, [sales])
  return (
    <ScreenPanel>
      <ScreenTitle label="Ventes" />
      <Tabs>
        <Tab eventKey="New" title={'Nouveaux (' + newItems.length + ')'}>
          <SaleInfo header />
          {newItems.map(item => (
            <SaleInfo key={item.id} info={item} onSelect={() => history.push(`/sales/${item.id}`)} />
          ))}
        </Tab>
        <Tab eventKey="Old" title={'Terminé (' + oldItems.length + ')'}>
          <SaleInfo header />
          {oldItems.map(item => (
            <SaleInfo key={item.id} info={item} onSelect={() => history.push(`/sales/${item.id}`)} />
          ))}
        </Tab>
      </Tabs>
    </ScreenPanel>
  )
}

export default SalesScreen
