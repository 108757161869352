import React from 'react'

const Restrict = ({allowed, roles, children}) => {
    if (!roles) {
        return null;
    }
    var isAllowed = false;
    allowed.forEach(a => {
        if (roles.includes(a)) {
            isAllowed = true;
        }
    })
    if (!isAllowed) {
        return null;
    }
    return (
        <>
          {children}  
        </>
    )
}

export default Restrict;
