import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ButtonBar from '../../components/button-bar/button-bar.component';
import CFAInput from '../../components/cfa-input/cfa-input.component';
import EuroInput from '../../components/euro-input/euro-input.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import { Server } from '../../components/server/server.component';
import StringInput from '../../components/string-input/string-input.component';
import TextInput from '../../components/text-input/text-input.component';
import { notify } from 'react-notify-toast';

const ArticleEditScreen = ({ match: { params: { id } } }) => {
    const { articles } = useContext(Server);
    const history = useHistory();
    const [data, setData] = useState({});
    const [error, setError] = useState();

    useEffect(() => {
        if (id !== "new") {
            articles.get(id, setData, setError);
        }
    }, [articles, id]);

    const save = () => {
        if (id === "new") {
            articles.create(data, response => {
                notify.show("Crée", 'success', 3000);
                history.push("/articles/" + response.id);
            }, () => { });
        } else {
            articles.update(id, data, response => {
                notify.show("Mise à jour", 'success', 3000);
                history.push("/articles/" + response.id);
            }, () => { });
        }
    };

    const cancel = () => {
        history.push(id === "new" ? "/articles" : "/articles/" + id);
    };

    return (
        <ScreenPanel>
            <ScreenTitle label={id === "new" ? "Créer nouvelle produit" : "Modifier produit"} />
            <StringInput label="Nom" value={data.name} onChange={text => setData({ ...data, name: text })} />
            <StringInput label="Code à barre" value={data.barcode} onChange={text => setData({ ...data, barcode: text })} />
            <EuroInput label="Prix d'achat" value={data.pricePurchasing} onChange={text => setData({ ...data, pricePurchasing: text })} />
            <CFAInput label="Prix ​​de vente" value={data.priceSelling} onChange={text => setData({ ...data, priceSelling: text })} />
            <TextInput label="Texte" value={data.text} onChange={text => setData({ ...data, text: text })} />
            <ButtonBar>
                <Button variant="secondary" onClick={cancel}>Annuler</Button>
                <Button variant="primary" onClick={save}>Sauvegarder</Button>
            </ButtonBar>
        </ScreenPanel>
    )
}

export default ArticleEditScreen;
