import React from 'react';

import styles from './button-bar.module.css';

const ButtonBar = ({ children }) => {
    return (
        <div className={styles.buttonBar}>
            {children}
        </div>
    )
}

export default ButtonBar;
