import React, { useContext, useEffect, useState } from 'react';
import { Server } from '../../components/server/server.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import User from '../../components/user/user.component';

const UsersScreen = () => {
    const [items, setItems] = useState([]);
    const [, setError] = useState();
    const { users } = useContext(Server);

    useEffect(() => {
        users.query(setItems, setError);
    }, [users])

    return (
        <ScreenPanel>
            <ScreenTitle label="Utilisateurs" />
            {
                items && items.map(item => <User key={item.id} user={item} />)
            }
        </ScreenPanel>
    )
}

export default UsersScreen;
