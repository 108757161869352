import { createContext, useState } from "react";

export const Search = createContext();

const SearchProvider = ({ children }) => {
    const [searches, setSearches] = useState({});
    return (
        <Search.Provider value={{
            saveSearch: (type, items) => {
                const n = { ...searches };
                n[type] = {
                    items: items,
                    current: 0,
                }
                setSearches(n);
            },
            get: type => {
                return searches[type]
            },
            current: type => {
                const data = searches[type];
                if (data) {
                    const items = data.items;
                    if (items && items.length > data.current) {
                        return items[data.current];
                    }
                }
                return null;
            },
            setCurrent: (type, index) => {
                const data = searches[type];
                if (data) {
                    const n = { ...searches };
                    n[type] = {
                        items: data.items,
                        current: index,
                    }
                    setSearches(n);
                }
            },
            hasNext: (type) => {
                const data = searches[type];
                if (data) {
                    const items = data.items;
                    return items.length - 1 > data.current;
                }
                return false;
            },
            next: (type) => {
                const data = searches[type];
                if (data) {
                    const items = data.items;
                    if (items.length - 1 > data.current) {
                        const n = { ...searches };
                        n[type] = {
                            items: items,
                            current: data.current + 1,
                        }
                        setSearches(n);
                        return items[n[type].current];
                    }
                }
                return null;
            },
            hasPrev: (type) => {
                const data = searches[type];
                if (data) {
                    return data.current > 0;
                }
                return false;
            },
            prev: (type) => {
                const data = searches[type];
                if (data) {
                    const items = data.items;
                    if (data.current > 0) {
                        const n = { ...searches };
                        n[type] = {
                            items: items,
                            current: data.current - 1,
                        }
                        setSearches(n);
                        return items[n[type].current];
                    }
                }
                return null;
            },
        }}>
            {children}
        </Search.Provider>
    );
}

export default SearchProvider;
