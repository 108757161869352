import React from 'react';

import styles from './screen-sub-title.module.css';

const ScreenSubTitle = ({ label }) => {

    return (
        <div className={styles.screenSubTitle}>
            {label}
        </div>
    )
}

export default ScreenSubTitle;
