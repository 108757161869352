import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import ButtonBar from '../button-bar/button-bar.component';

export default function SearchBox({ onQuery, onChangeText, children }) {
    const [query, setQuery] = useState("");

    const handleKeyPress = evt => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            onQuery(query);
        }
    }

    return (
        <>
            <Form.Group>
                <Form.Control placeholder="Texte de recherche" value={query} onKeyPress={handleKeyPress} onChange={({target: {value}}) => {
                    if (onChangeText) {
                        onChangeText(value);
                    }
                    setQuery(value);
                }} />
            </Form.Group>
            <ButtonBar>
                {children}
                <Button variant="primary" onClick={() => onQuery(query)}>Chercher</Button>
            </ButtonBar>
        </>
    )
}
