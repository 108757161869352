import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ArticleInfo from '../../components/article-info/article-info.component';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import SearchBox from '../../components/search-box/search-box.component';
import { Server } from '../../components/server/server.component';
import { useHistory } from "react-router-dom";
import { Search } from '../../components/search/search.component';
import { notify } from 'react-notify-toast';

const ArticlesScreen = () => {
    const { articles } = useContext(Server)
    const { saveSearch, get } = useContext(Search);
    const [items, setItems] = useState([]);
    const [error, setError] = useState();
    const [sort, setSort] = useState({ column: "name", direction: "asc" });
    const history = useHistory();

    const compare = (a, b) => {
        if (a) {
            if (typeof (a) === 'string') {
                return a.toLowerCase() < b.toLowerCase() ? -1 : (a.toLowerCase() === b.toLowerCase() ? 0 : 1);
            }
            return a < b ? -1 : (a === b ? 0 : 1);
        }
        if (b) {
            return -1;
        }
        return 0;
    }

    useEffect(() => {
        const articles = get('articles')
        console.log("#######", articles)
        if (articles && articles.items && articles.items.length) {
            setItems(articles.items)
        }
    }, [get])

    useEffect(() => {
        items.sort((a, b) => {
            var va = a[sort.column];
            if (typeof va === "string") {
                va = va.toLowerCase();
            }
            var vb = b[sort.column];
            if (typeof ba === "string") {
                vb = vb.toLowerCase();
            }
            return (sort.direction === "asc" ? 1 : -1) * compare(va, vb);
        });
        saveSearch("articles", items);
    }, [items, sort])

    const query = q => {
        articles.query(q, r => {
            setItems(r);
        }, setError)
    }
    return (
        <ScreenPanel>
            <ScreenTitle label="Articles" />
            <SearchBox onQuery={query} >
                <Button variant="secondary" onClick={() => {
                    notify.show("Veuillez patienter pendant la création du catalogue.", 'success', 3000);
                    articles.catalog(setError)}
                }>Catalogue</Button>
                <Button variant="secondary" onClick={() => history.push(`/articles/new/edit`)}>Créer</Button>
            </SearchBox>
            <ArticleInfo key={"header"} header sort={sort} onSort={setSort} />
            {
                items.map((item, idx) => <ArticleInfo key={item.id} article={item} index={idx} />)
            }
        </ScreenPanel>
    )
}

export default ArticlesScreen;
