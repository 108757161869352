import React from 'react'
import { useHistory } from 'react-router';
import {Cfa, Euro } from 'amango-ui'

import styles from './account-info.module.css';

const AccountInfo = ({ account, header }) => {
    const history = useHistory();

    const select = () => {
        history.push(`/accounts/${account.id}`);
    };

    if (header) {
        return <div className={styles.header}>
            <div className={styles.accountInfo}>
                <span>Nom</span>
                <span className={styles.prices} >
                    <span>Solde du compte (€)</span>
                    <span>Solde du compte (CFA)</span>
                </span>
            </div>
        </div>;
    }

    return (
        <div className={styles.accountInfo} onClick={select}>
            <span>{account.name}</span>
            <span className={styles.prices} >
                <Euro euro={account.balance} />
                <Cfa euro={account.balance} />
            </span>
        </div>
    )
}

export default AccountInfo
