/** @format */

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyCBbIEWYyEFbnkMl2PWD0PCVg6oH7ETR_Q',
  authDomain: 'keke-d69ed.firebaseapp.com',
  projectId: 'keke-d69ed',
  storageBucket: 'keke-d69ed.appspot.com',
  messagingSenderId: '49116745052',
  appId: '1:49116745052:web:c4a5ba3ca066d504bdc599',
}

firebase.initializeApp(config)

export const auth = firebase.auth()
export const firestore = firebase.firestore()

const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })
export const signInWithGoogle = () => auth.signInWithPopup(provider)

export default firebase
