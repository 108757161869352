import React, { useState } from 'react'
import { ButtonGroup, ButtonToolbar, ToggleButton } from 'react-bootstrap';
import ScreenPanel from '../../components/screen-panel/screen-panel.component';
import ScreenTitle from '../../components/screen-title/screen-title.component';
import TransferReceive from '../../components/transfer-receive/transfer-receive.component';
import TransferSend from '../../components/transfer-send/transfer-send.component';
import TransferTransfer from '../../components/transfer-transfer/transfer-transfer.component';

import styles from './transfer.module.css';

const TransferScreen = () => {
    const [action, setAction] = useState();



    return (
        <ScreenPanel>
            <ScreenTitle label="Transfert" />
            <ButtonToolbar>
                <ButtonGroup toggle className={styles.btnGroup}>
                    <ToggleButton
                        type="radio"
                        variant="success"
                        name="action"
                        value="S"
                        checked={action === 'S'}
                        onChange={({ currentTarget: { value } }) => setAction(value)}
                    >J'envoie un colis</ToggleButton>
                </ButtonGroup>
                <ButtonGroup toggle className={styles.btnGroup}>
                    <ToggleButton
                        type="radio"
                        variant="success"
                        name="action"
                        value="R"
                        checked={action === 'R'}
                        onChange={({ currentTarget: { value } }) => setAction(value)}
                    >J'ai reçu un colis</ToggleButton>
                </ButtonGroup>
                <ButtonGroup toggle className={styles.btnGroup}>
                    <ToggleButton
                        type="radio"
                        variant="success"
                        name="action"
                        value="T"
                        checked={action === 'T'}
                        onChange={({ currentTarget: { value } }) => setAction(value)}
                    >Marchandises ont été transférées dans un autre entrepôt</ToggleButton>
                </ButtonGroup>
            </ButtonToolbar>

            {
                action === 'S' && <TransferSend/>
            }
            {
                action === 'R' && <TransferReceive/>
            }
            {
                action === 'T' && <TransferTransfer/>
            }
        </ScreenPanel>
    )
}

export default TransferScreen
