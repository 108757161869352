import React from 'react';
import styles from './input.module.css';

const Input = ({ label, placeholder, value, onChange, type, onKeyPress }) => {
    const l = value ? <div className={styles.label}>{label}</div> : <div className={styles.label}>{""}</div>;
    return (
        <div className={styles.input}>
            {l}
            <input
                type={type}
                value={value ? value : ""}
                onChange={({target: {value}}) => onChange(value)}
                onKeyPress={onKeyPress ? onKeyPress : () => {}}
                placeholder={placeholder ? placeholder : label} />
        </div>
    )
}

export default Input
