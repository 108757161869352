import React from 'react';

import styles from './transfer-item.module.css';

const TransferItem = ({ item, header }) => {
    if (header) {
        return <div className={styles.header}>
            <div className={styles.transferItem}>
                <div className={styles.barcode}>Code à barre</div>
                <div className={styles.name}>Nom</div>
                <div className={styles.count}>Nombre</div>
                <div className={styles.count}>Reçu</div>
            </div>
        </div>;
    }
    if (item.count === 0) {
        return null;
    }
    return (
        <div className={styles.transferItem}>
            <div className={styles.barcode}>{item.barcode}</div>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.count}>{item.count}</div>
            <div className={styles.count}></div>
        </div>
    )
}

export default TransferItem;
