import React from 'react'
import {Cfa, Euro } from 'amango-ui'

import styles from './transaction.module.css';

const fmtFR = new Intl.DateTimeFormat('fr-FR');

const Transaction = ({ transaction, header }) => {
    if (header) {
        return <div className={styles.header}>
            <div className={styles.accountInfo}>
                <span>Date</span>
                <span>Nom</span>
                <span>Valeur (€)</span>
                <span>Valeur (CFA)</span>
                <span>Solde du compte (€)</span>
                <span>Solde du compte (CFA)</span>
            </div>
        </div>;
    }

    return (
        <div className={styles.accountInfo}>
            <span>{fmtFR.format(new Date(transaction.date))}</span>
            <span>{transaction.name}</span>
            <span><Euro euro={transaction.amount} /></span>
            <span><Cfa euro={transaction.amount} /></span>
            <span><Euro euro={transaction.balance} /></span>
            <span><Cfa euro={transaction.balance} /></span>
        </div>
    )
}

export default Transaction
