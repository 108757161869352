import React from 'react'

import styles from './account-select.module.css';

const AccountSelect = ({ items, value, label, onChange }) => {
    return (
        <div className={styles.select}>
            <div className={styles.label}>{label}</div>
            <select value={value} onChange={({ target: { value } }) => onChange(value)}>
                <option>Bitte wählen...</option>
                {items && items.map(item => <option value={item.id}>{item.name}</option>)}
            </select>
        </div>
    )
}

export default AccountSelect
