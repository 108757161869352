import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import ButtonBar from '../button-bar/button-bar.component';
import EuroInput from '../euro-input/euro-input.component';
import { Server } from '../server/server.component';
import StringInput from '../string-input/string-input.component'
import TransferList from '../transfer-list/transfer-list.component';
import { notify } from 'react-notify-toast';
import { useHistory } from "react-router-dom";

import styles from './transfer-send.module.css';

const TransferSend = () => {
    const { articles, transfers, warehouses } = useContext(Server);
    const [name, setName] = useState();
    const [price, setPrice] = useState(0);
    const [planned, setPlanned] = useState([]);
    const [sourceItems, setSourceItems] = useState([]);
    const [articleItems, setArticleItems] = useState();
    const [warehouse, setWarehouse] = useState();
    const [items, setItems] = useState([]);
    const [saved, setSaved] = useState();
    const history = useHistory();

    const buildSourceItemns = (articleItems, warehouse, saved) => {
        if (articleItems && warehouse) {
            var map = { ...warehouse.items };
            if (saved) {
                saved.items.forEach(i => {
                    map[i.articleId] -= i.count;
                });
                console.log("saved", saved);
                setName(saved.name);
                setPrice(saved.price);
                setItems(saved.items.map(i => ({
                    ...i,
                    id: i.articleId,
                    barcode: articleItems[i.articleId].barcode,
                    name: articleItems[i.articleId].name,
                })));
            }
            const l = [];
            Object.keys(map).forEach(articleId => {
                const art = articleItems[articleId];
                if (!art) {
                    console.log('missing article', articleId)
                }
                l.push({
                    id: articleId,
                    barcode: art?.barcode,
                    name: art?.name,
                    count: map[articleId],
                });
            });
            setSourceItems(l);
        }
    }

    useEffect(() => {
        transfers.byStatus("PLANNED", setPlanned, console.log);
    }, [transfers]);

    useEffect(() => {
        warehouses.loadMine(data => {
            setWarehouse(data);
        }, console.log);
    }, [warehouses]);

    useEffect(() => {
        buildSourceItemns(articleItems, warehouse, saved);
    }, [articleItems, warehouse, saved]);

    useEffect(() => {
        articles.query(null, data => {
            const m = {};
            data.forEach(art => m[art.id] = art);
            setArticleItems(m);
        }, console.log);
    }, [articles]);

    const load = plan => {
        transfers.get(plan.id, setSaved, console.log);
    };

    const save = () => {
        if (saved) {
            transfers.update(saved.id, {
                name,
                price,
                type: "CARTON",
                items: items.map(i => ({ articleId: i.id, count: i.count })),
            }, () => notify.show("enregistré", 'success', 3000), console.log);
        } else {
            transfers.create({
                name,
                price,
                type: "CARTON",
                items: items.map(i => ({ articleId: i.id, count: i.count })),
            }, () => notify.show("enregistré", 'success', 3000), console.log);
        }
    }

    const send = () => {
        if (saved) {
            transfers.updateAndTerminate(saved.id, {
                name,
                price,
                type: "CARTON",
                items: items.map(i => ({ articleId: i.id, count: i.count })),
            }, () => {
                notify.show("envoyé", 'success', 3000)
                history.push("/")
            }, console.log);
        } else {
            transfers.createAndTerminate({
                name,
                price,
                type: "CARTON",
                items: items.map(i => ({ articleId: i.id, count: i.count })),
            }, () => {
                notify.show("envoyé", 'success', 3000)
                history.push("/")
            }, console.log);
        }
    }

    return (
        <>
            <div>
                <div>Paquets non encore envoyés</div>
                {
                    planned && planned.map(p => <div className={styles.planned} onClick={() => load(p)}>
                        <span>{p.name}</span>
                        <span>{p.price}</span>
                        <span>{p.itemCount}</span>
                    </div>)
                }
            </div>
            <div>
                <StringInput label="Nom" value={name} onChange={setName} />
                <EuroInput label="Prix de transfert" value={price} onChange={setPrice} />
                <TransferList sourceTitle="Mon Entrepôt" targetTitle="Le carton" sourceItems={sourceItems} value={items} onChange={setItems} />
                <ButtonBar>
                    <Button variant="secondary" onClick={save}>Sauvegarder</Button>
                    <Button onClick={send}>Envoyer</Button>
                </ButtonBar>
            </div>
        </>
    )
}

export default TransferSend
