import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router'
import AuditInfo from '../../components/audit-info/audit-info.component'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import WarehouseInventoryItem from '../../components/warehouse-inventory-item/warehouse-inventory-item.component'

const fmtFR = new Intl.DateTimeFormat('fr-FR')

const WarehouseInventoryScreen = ({
  match: {
    params: { id },
  },
}) => {
  const { articles, warehouses, sales } = useContext(Server)
  const history = useHistory()
  const [data, setData] = useState({})
  const [items, setItems] = useState([])
  const [error, setError] = useState()
  const [articleItems, setArticleItems] = useState([])
  const [articleCount, setArticleCount] = useState(0)
  const [sort, setSort] = useState({ column: 'name', direction: 'asc' })
  const [filter, setFilter] = useState('')
  const [opened, setOpened] = useState(false)

  const compare = (a, b) => {
    if (a) {
      if (typeof a === 'string') {
        return a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() === b.toLowerCase() ? 0 : 1
      }
      return a < b ? -1 : a === b ? 0 : 1
    }
    if (b) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    if (data && data.items && articleItems) {
      var l = []
      Object.keys(data.items).forEach(i => {
        const a = {
          id: i,
          count: data.items[i],
          ...articleItems[i],
        }
        if (a.name?.toLowerCase().includes(filter) || a.barcode?.toLowerCase().includes(filter)) {
          console.log('filter:', filter, a.barcode?.toLowerCase(), a.barcode?.toLowerCase().includes(filter))
          l.push(a)
        }
      })
      l.sort((a, b) => {
        var va = a[sort.column]
        if (typeof va === 'string') {
          va = va.toLowerCase()
        }
        var vb = b[sort.column]
        if (typeof ba === 'string') {
          vb = vb.toLowerCase()
        }
        return (sort.direction === 'asc' ? 1 : -1) * compare(va, vb)
      })
      setItems(l)
    }
  }, [sort, articleItems, data, filter])

  useEffect(() => {
    warehouses.get(id, setData, setError)
  }, [warehouses, id])

  useEffect(() => {
    articles.query(
      null,
      data => {
        const m = {}
        data.forEach(art => (m[art.id] = art))
        setArticleItems(m)
      },
      console.log
    )
  }, [articles])

  useEffect(() => {
    if (data.items) {
      var cnt = 0
      Object.keys(data.items).forEach(i => (cnt += data.items[i]))
      setArticleCount(cnt)
    }
  }, [data])

  const terminate = () => {
    setOpened(true)
    console.log('terminate >>>>>>>>>', items)
  }

  const update = (changedItem, counted) => {
    setItems(items.map(item => (item.id === changedItem.id ? { ...item, counted } : item)))
  }

  const createSale = () => {
    sales.create(
      { date: new Date().toISOString().split('T')[0], name: `Inventaire ${fmtFR.format(new Date())}`, items: items.filter(item => item.counted !== undefined).map(item => ({ barcode: item.barcode, count: item.count - item.counted })) },
      data => history.push(`/sales/${data.id}`),
      console.error
    )
  }

  const changeStock = () => {
    const body = items.reduce((accumulated, item) => {
      console.log('accumulated', accumulated, 'item', item)
      if (item.counted !== undefined) {
        accumulated[item.id] = item.counted
      }
      return accumulated
    }, {})
    warehouses.inventory(id, body, history.goBack, console.error)
  }

  return (
    <ScreenPanel>
      <ScreenTitle label="Entrepôt" />
      <KeyValueRow k="Nom">{data.name}</KeyValueRow>
      <KeyValueRow k="Nombre d'articles">{articleCount}</KeyValueRow>
      <AuditInfo data={data} />
      <div style={{ textAlign: 'right' }}>
        <input value={filter} onChange={({ target: { value } }) => setFilter(value.toLowerCase())} />
      </div>
      <WarehouseInventoryItem header sort={sort} onSort={setSort} />
      {items && items.map(item => <WarehouseInventoryItem key={item.id} item={item} onUpdate={update} />)}
      <Button onClick={terminate}>Terminer</Button>
      <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header>
          <Modal.Title>Terminer</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpened(false)}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              setOpened(false)
              createSale()
            }}
          >
            Créer une vente
          </Button>
          <Button
            onClick={() => {
              changeStock()
              setOpened(false)
            }}
          >
            Changer de stock
          </Button>
        </Modal.Footer>
      </Modal>
    </ScreenPanel>
  )
}

export default WarehouseInventoryScreen
