import React, { useContext, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import PurchaseInfo from '../../components/pruchase-info/purchase-info.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Server } from '../../components/server/server.component'
import { useHistory } from "react-router-dom";

const PurchasesScreen = () => {
    const { purchases } = useContext(Server);
    const [oldItems, setOldItems] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const history = useHistory();
    useEffect(() => {
        purchases.load(data => {
            setOldItems(data.filter(d => d.addedToWarehouse && d.addedToAccount));
            setNewItems(data.filter(d => !d.addedToWarehouse || !d.addedToAccount));
        }, () => { });
    }, [purchases])
    return (
        <ScreenPanel>
            <ScreenTitle label="Achats" />
            <Tabs>
                <Tab eventKey="New" title={"Nouveaux (" + newItems.length + ")"}>
                    <PurchaseInfo header />
                    {
                        newItems.map(item => <PurchaseInfo info={item}
                            onSelect={() => history.push(`/purchases/${item.id}`)} />)
                    }
                </Tab>
                <Tab eventKey="Old" title={"Terminé (" + oldItems.length + ")"}>
                    <PurchaseInfo header />
                    {
                        oldItems.map(item => <PurchaseInfo info={item}
                            onSelect={() => history.push(`/purchases/${item.id}`)} />)
                    }
                </Tab>
            </Tabs>
        </ScreenPanel>
    )
}

export default PurchasesScreen
