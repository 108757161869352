/** @format */

import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import ButtonBar from '../../components/button-bar/button-bar.component'
import KeyValueRow from '../../components/key-value-row/key-value-row.component'
import ScreenPanel from '../../components/screen-panel/screen-panel.component'
import ScreenTitle from '../../components/screen-title/screen-title.component'
import { Search } from '../../components/search/search.component'
import { Server } from '../../components/server/server.component'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'

import ScreenSubTitle from '../../components/screen-sub-title/screen-sub-title.component'
import {Cfa, Euro, toEuro } from 'amango-ui'

import styles from './article-details.module.css'
import AuditInfo from '../../components/audit-info/audit-info.component'

const fmtPct = new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })

const ArticleDetailsScreen = ({
  match: {
    params: { id },
  },
}) => {
  const { hasNext, next, hasPrev, prev } = useContext(Search)
  const { articles, image } = useContext(Server)
  const history = useHistory()
  const [data, setData] = useState({})
  const [stock, setStock] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    articles.get(id, setData, setError)
  }, [articles, id])

  useEffect(() => {
    articles.stock(id, setStock, setError)
  }, [articles, id])

  const profit = toEuro(data.priceSelling) - data.pricePurchasing

  return (
    <ScreenPanel>
      <div className={styles.header}>
        <ScreenTitle label={data.name} />
        <ButtonBar>
          <Button
            disabled={!hasPrev('articles')}
            onClick={() => {
              const p = prev('articles')
              history.replace(`/articles/${p.id}`)
            }}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            disabled={!hasNext('articles')}
            onClick={() => {
              const n = next('articles')
              history.replace(`/articles/${n.id}`)
            }}>
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </ButtonBar>
      </div>
      <div className={styles.panel}>
        <div>{data.image && <img alt='' src={image(data.barcode)} className={styles.image} />}</div>
        <div>
          <ReactMarkdown children={data.text} />
          <KeyValueRow k='Code à barre'>{data.barcode}</KeyValueRow>
          <KeyValueRow k="Prix d'achat">
            <Euro euro={data.pricePurchasing} /> / <Cfa euro={data.pricePurchasing} />
          </KeyValueRow>
          <KeyValueRow k='Prix ​​de vente'>
            <Cfa cfa={data.priceSelling} /> / <Euro cfa={data.priceSelling} />
          </KeyValueRow>
          <KeyValueRow k='Profit'>
            <Euro euro={profit} /> / <Cfa euro={profit} /> / {fmtPct.format(profit / data.pricePurchasing)}
          </KeyValueRow>
          <AuditInfo data={data} />
        </div>
      </div>
      <ButtonBar>
        <Button variant='secondary' onClick={() => history.replace('/articles')}>
          Annuler
        </Button>
        <Button variant='secondary' onClick={() => history.push(`/articles/${id}/lookup`)}>
          Assistant de recherche
        </Button>
        <Button onClick={() => history.push(`/articles/${id}/edit`)}>Modifier</Button>
      </ButtonBar>
      <ScreenSubTitle label='Niveau de stock' />
      <div className={styles.header1}>Entrepôts</div>
      <div className={styles.header2}>
        <div className={styles.stockInfo}>
          <div>Nom d'entrepôt</div>
          <div>Nombre</div>
        </div>
      </div>
      {stock &&
        stock
          .filter(s => s.type === 'WAREHOUSE')
          .map(s => (
            <div className={styles.stockInfo}>
              <div>{s.name}</div>
              <div>{s.count}</div>
            </div>
          ))}
      <div className={styles.header1}>Transferts</div>
      <div className={styles.header2}>
        <div className={styles.stockInfo}>
          <div>Nom de transfert</div>
          <div>Nombre</div>
        </div>
      </div>
      {stock &&
        stock
          .filter(s => s.type === 'TRANSFER')
          .map(s => (
            <div className={styles.stockInfo}>
              <div>{s.name}</div>
              <div>{s.count}</div>
            </div>
          ))}
    </ScreenPanel>
  )
}

export default ArticleDetailsScreen
