import React from 'react';

import styles from './text-input.module.css';

const TextInput = ({ label, placeholder, value, onChange }) => {
    const l = value ? <div className={styles.label}>{label}</div> : <div className={styles.label}>{""}</div>;
    return (
        <div className={styles.input}>
            {l}
            <textarea value={value ? value : ""}
                onChange={({ target: { value } }) => onChange(value)}
                placeholder={placeholder ? placeholder : label} />
        </div>
    )
}

export default TextInput;
