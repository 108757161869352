/** @format */

import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import PageHeader from './components/page-header/page-header.component'

import ServerProvider from './components/server/server.component'
import SearchProvider from './components/search/search.component'
import Notifications from 'react-notify-toast'
import WaitDialog from './components/wait-dialog/wait-dialog.component'

import HomeScreen from './screens/home/home.screen'
import SignInScreen from './screens/signin/signin.screen'
import UsersScreen from './screens/users/users.screen'
import ArticlesScreen from './screens/articles/articles.screen'
import ArticleEditScreen from './screens/article-edit/article-edit.screen'
import ArticleDetailsScreen from './screens/article-details/article-details.screen'
import PurchasesScreen from './screens/purchases/purchases.screen'
import SalesScreen from './screens/sales/sales.screen'
import PurchaseDetailsScreen from './screens/purchase-details/purchase-details.screen'
import TransferScreen from './screens/transfer/transfer.screen'
import WarehousesScreen from './screens/warehouses/warehouses.screen'
import AccountsScreen from './screens/accounts/accounts.screen'
import AccountDetailsScreen from './screens/account-details/account-details.screen'
import WarehouseDetailsScreen from './screens/warehouse-details/warehouse-details.screen'
import SaleDetailsScreen from './screens/sale-details/sale-details.screen'
import TransferDetailsScreen from './screens/transfer-details/transfer-details.screen'
import WarehouseEditScreen from './screens/warehouse-edit/warehouse-edit.screen'
import UserDetailsScreen from './screens/user-details/user-details.screen'
import WarehouseInventoryScreen from './screens/warehouse-inventory/warehouse-inventory.screen'

import './App.css'
import ArticleLookupScreen from './screens/article-lookup/article-lookup.screen'

const App = () => {
  return (
    <div className='App'>
      <ServerProvider>
        <SearchProvider>
          <Router>
            <Notifications options={{ zIndex: 10000, top: '50px' }} />
            <PageHeader />
            <Switch>
              <Route exact path='/' component={HomeScreen} />
              <Route exact path='/signin' component={SignInScreen} />

              <Route exact path='/users' component={UsersScreen} />
              <Route exact path='/users/:id' component={UserDetailsScreen} />

              <Route exact path='/accounts' component={AccountsScreen} />
              <Route exact path='/accounts/:id' component={AccountDetailsScreen} />

              <Route exact path='/articles' component={ArticlesScreen} />
              <Route exact path='/articles/:id' component={ArticleDetailsScreen} />
              <Route exact path='/articles/:id/edit' component={ArticleEditScreen} />
              <Route exact path='/articles/:id/lookup' component={ArticleLookupScreen} />

              <Route exact path='/purchases' component={PurchasesScreen} />
              <Route exact path='/purchases/:id' component={PurchaseDetailsScreen} />

              <Route exact path='/sales' component={SalesScreen} />
              <Route exact path='/sales/:id' component={SaleDetailsScreen} />

              <Route exact path='/transfers' component={TransferScreen} />
              <Route exact path='/transfers/:id' component={TransferDetailsScreen} />

              <Route exact path='/warehouses' component={WarehousesScreen} />
              <Route exact path='/warehouses/:id' component={WarehouseDetailsScreen} />
              <Route exact path='/warehouses/:id/edit' component={WarehouseEditScreen} />
              <Route exact path='/warehouses/:id/inventory' component={WarehouseInventoryScreen} />
            </Switch>
          </Router>
        </SearchProvider>
      </ServerProvider>
      <WaitDialog />
    </div>
  )
}

export default App
